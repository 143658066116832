import axios from 'axios';
var CryptoJS = require('crypto-js');
const hostname = window.location.hostname;

// return the user data from the session storage
export const getUser = () => {
    // const userStr = sessionStorage.getItem('user');
    const userStr = localStorage.getItem('user');
    if (userStr == null) return null;
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(userStr, 'my-secret-key@123');
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (userStr) return decryptedData;
    else return null;
};

// return the token from the session storage
export const getToken = () => {
    // return sessionStorage.getItem('token') || null;
    return localStorage.getItem('token') || null;
};

// return language from the session storage
export const getLanguage = () => {
    // return sessionStorage.getItem('lang') || null;
    return localStorage.getItem('lang') || null;
};

// return the ip from the session storage
export const getIP = () => {
    // return sessionStorage.getItem('ipData') || null;
    return localStorage.getItem('ipData') || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('lang');
    sessionStorage.removeItem('ipData');
};

export const removeUserLocal = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('lang');
    localStorage.removeItem('ipData');
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(user), 'my-secret-key@123').toString();
    //sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('user', ciphertext);
};

export const setUserLocal = (token, user) => {
    localStorage.setItem('token', token);
    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(user), 'my-secret-key@123').toString();
    //sessionStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('user', ciphertext);
};

export const saveIPAddress = async () => {
    let ipAdd = null;
    try {
        const ipData = await axios.get(`//${hostname.includes('interone.ai') ? '/interone.ai' : '/interx.ai'}/api_framework/account/get_ip_api`);
        ipAdd = ipData.data.data.o_data.user_log_ip;
        sessionStorage.setItem('ipData', ipAdd);
    } catch (err) {
        const ipData = await axios.get(`//${hostname.includes('interone.ai') ? '/interone.ai' : '/interx.ai'}/api_framework/account/get_ip_api`);
        ipAdd = ipData.data.data.o_data.user_log_ip;
        sessionStorage.setItem('ipData', ipAdd);
        console.log('error while saving ip address');
    } finally {
        if (ipAdd == null) {
            ipAdd = '0.0.0.0';
            sessionStorage.setItem('ipData', ipAdd);
            console.log('error while saving ip address');
        }
        return ipAdd;
    }
};

export const setServiceMappingInfo = (info) => {
    sessionStorage.setItem('serviceMappingInfo', info);
    // localStorage.setItem('serviceMappingInfo', info);
};

export const getServiceMappingInfo = () => {
    return JSON.parse(sessionStorage.getItem('serviceMappingInfo')) || null;
    // return JSON.parse(localStorage.getItem('serviceMappingInfo')) || null;
};

export const setMenuMappingInfo = (info) => {
    sessionStorage.setItem('menuMappingInfo', info);
    // localStorage.setItem('menuMappingInfo', info);
};

//export const getMenuMappingInfo = () => {
//  const path = location.pathname.replace('/', '').toLowerCase();
//  let info = JSON.parse(sessionStorage.getItem('menuMappingInfo'));
//  if (info === null) return null;
//
//  while (1) {
//    info = JSON.parse(sessionStorage.getItem('menuMappingInfo'));
//    if (path === info.path.toLowerCase()) break;
//  }
//
//  return info;
//}

export const getMenuMappingInfo = () => {
    return JSON.parse(sessionStorage.getItem('menuMappingInfo')) || null;
    // return JSON.parse(localStorage.getItem('menuMappingInfo')) || null;
};

export const removeServiceMappingInfo = () => {
    sessionStorage.removeItem('serviceMappingInfo');
    // localStorage.removeItem('serviceMappingInfo');
};

export const removeMenuMappingInfo = () => {
    sessionStorage.removeItem('menuMappingInfo');
    // localStorage.removeItem('menuMappingInfo');
};

export const getServicePath = (service) => {
    let serviceStr = service === 'framework' ? `/${service}` : `/${service}ai`;
    serviceStr = service === 'digitaltwin' ? '/digitaltwinai' : serviceStr;
    serviceStr = service === 'GEN.INTERX' ? '' : serviceStr;

    if (hostname.includes('interx.ai') || hostname.includes('interone.ai')) {
        // production, testing server
        return `${hostname}${serviceStr}/`;
    } else {
        // local
        if (service === 'recipe' || service === 'quality') serviceStr = '';
        const portNum = {
            framework: 3001,
            recipe: 3006,
            quality: 3006,
            uxrecipeai: 3016,
            uxqualityai: 3016,
            digitaltwin: 3002,
            inspection: 3005,
        };
        return `${hostname}:${portNum[service]}${serviceStr}/`;
    }
};

// const getBaseApi = () => {  // api url
//     const url = hostname === 'api.interone.ai' ? hostname : "10.10.54.141";
//     const portNum = hostname === 'api.interone.ai' ? 89 : 9005;
//     return `//${url}:${portNum}/api_framework/`;
// }

// export const Baseapi = {
//     //pythonWebAPI: "http://127.0.0.1:8000/"
//     pythonWebAPI: getBaseApi()
//     //pythonWebAPI: "http://server.interxlab.io:5681/"
//     //pythonWebAPI: "http://10.10.54.56:91/"
// };

export const getParam = (target) => {
    const query = window.location.search?.replace(/\+/g, '%2B'); //replace is needed because new URLSearchParams(query) make "+"" to blank
    const param = new URLSearchParams(query);
    let val = param.get(target)?.replace('%2B', /\+/g);

    val = target === 'caption' ? decodeURI(decodeURIComponent(val)) : val;
    return val;
};

export const moveService = (menu) => {
    let servicePath = getServicePath(menu.template_master_type);
    const path = menu.template_master_path;
    //if (menu.template_master_type !== "framework") {
    //    removeUserSession();
    //}

    removeMenuMappingInfo();
    removeServiceMappingInfo();
    if (menu.template_master_type.includes('digital') || menu.template_master_type.includes('GEN') || menu.template_master_type.includes('DOCUMENT')) {
        const id = menu.menu_mapping_id;
        const type = menu.template_master_type;
        const caption = encodeURI(encodeURIComponent(menu.menu_mapping_caption));
        // const lang = sessionStorage.getItem('lang');
        // const user = sessionStorage.getItem('user');
        const lang = localStorage.getItem('lang');
        const user = localStorage.getItem('user');
        window.location.href = `${window.location.protocol}//${servicePath}${path}?id=${id}&type=${type}&caption=${caption}&lang=${lang}&user=${user}`;
    } else if (window.location.hostname.includes('inter')) {
        window.location.href = `${window.location.protocol}//${servicePath}${path}${menu.template_master_type === 'recipe' ? '?r=1' : ''}`;
    } else {
        window.location.href = `${window.location.protocol}//${servicePath}${path}${menu.template_master_type === 'recipe' ? '?r=1' : '?'}&user=${localStorage.getItem('user')}&token=${localStorage.getItem('token')}`;
    }
    // window.location.href = `${window.location.protocol}//${servicePath}${path}?id=${id}&type=${type}&caption=${caption}&lang=${lang}&user=${user}`;
    // window.location.href = type === 'digitaltwin' ? `http://server.interxlab.io:5684/Dashboard?id=${id}&type=${type}&caption=${caption}&lang=${lang}&user=${user}` : `${window.location.protocol}//${servicePath}${path}?id=${id}&type=${type}&caption=${caption}&lang=${lang}&user=${user}`;
};

export const redirectToLoginPage = () => {
    let servicePath = getServicePath('framework');

    console.log(servicePath);
    window.location.href = `${window.location.protocol}//${servicePath}login`;
};
